import { createTheme } from "@mui/material/styles";

export const lightTheme = createTheme({
    palette: {
      mode: "light",
      primary: { main: "#FF85A1" },
      secondary: { main: "#FFB7C5" },
      background: { 
        default: "#FFF5F6",
        paper: "#FFFFFF"
      },
      text: {
        primary: "#4A4A4A",
        secondary: "#FF85A1"
      }
    },
  });
  
  export const darkTheme = createTheme({
    palette: {
      mode: "dark",
      primary: { main: "#FF85A1" },
      secondary: { main: "#5a1a41" },
      background: { 
        default: "#1A1A1A",
        paper: "#2D2D2D"
      },
      text: {
        primary: "#FFC0CB",
        secondary: "#FF85A1"
      }
    },
  });
import { useState } from 'react';
import supabase from './supabaseClient';
import { Box, TextField, Button, Typography } from '@mui/material';

export default function Auth() {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');
  const [passcode, setPasscode] = useState('');

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const { data, error } = await supabase
        .from('users')
        .select()
        .eq('name', name)
        .eq('passcode', passcode);

      if (error) throw error;
      
      // Check if we got exactly one user
      if (data && data.length === 1) {
        localStorage.setItem('user', JSON.stringify(data[0]));
        window.location.reload();
      } else {
        alert('Invalid name or passcode');
      }
    } catch (error) {
      alert(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ 
      maxWidth: 400, 
      margin: '100px auto', 
      padding: 3,
      backgroundColor: '#2D2D2D',
      borderRadius: 2,
      textAlign: 'center'
    }}>
      <Typography variant="h5" sx={{ color: '#FFC0CB', marginBottom: 3 }}>
        Horror Movie Tracker Login
      </Typography>
      <Box component="form" sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <TextField
          label="Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          sx={{
            input: { color: '#FFC0CB' },
            '& label': { color: '#FFC0CB' },
            '& label.Mui-focused': { color: '#FFC0CB' },
            '& .MuiOutlinedInput-root': {
              '& fieldset': { borderColor: '#FFC0CB50' },
              '&:hover fieldset': { borderColor: '#FFC0CB' },
              '&.Mui-focused fieldset': { borderColor: '#FFC0CB' },
            },
          }}
        />
        <TextField
          type="password"
          label="Passcode"
          value={passcode}
          onChange={(e) => setPasscode(e.target.value)}
          sx={{
            input: { color: '#FFC0CB' },
            '& label': { color: '#FFC0CB' },
            '& label.Mui-focused': { color: '#FFC0CB' },
            '& .MuiOutlinedInput-root': {
              '& fieldset': { borderColor: '#FFC0CB50' },
              '&:hover fieldset': { borderColor: '#FFC0CB' },
              '&.Mui-focused fieldset': { borderColor: '#FFC0CB' },
            },
          }}
        />
        <Button
          onClick={handleLogin}
          disabled={loading}
          sx={{
            backgroundColor: '#5a1a41',
            color: '#FFC0CB',
            '&:hover': { backgroundColor: '#7a2a51' },
          }}
        >
          {loading ? 'Loading...' : 'Sign In'}
        </Button>
      </Box>
    </Box>
  );
} 
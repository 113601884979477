import React, { useEffect, useState } from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, Button, Typography, ThemeProvider, CssBaseline, Autocomplete, Tooltip } from "@mui/material";
import supabase from "./supabaseClient";
import API_KEY from "./client";
import { lightTheme, darkTheme } from "./theme";
import Auth from './Auth';

function MovieRatings() {
  const [movies, setMovies] = useState([]);
  const [darkMode, setDarkMode] = useState(true);
  const [newMovie, setNewMovie] = useState({ title: "", description: "", image_url: "", release_date: "", score_story: "0", score_scary: "0", score_acting: "0", score_vibes: "0" });
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const fetchMovies = async () => {
      const { data, error } = await supabase
        .from("movies")
        .select("*")
        .order('id', { ascending: true });
      
      if (error) console.error(error);
      else {
        setMovies(data);
      }
    };
    fetchMovies();
  }, []);

  useEffect(() => {
    const user = localStorage.getItem('user');
    if (user) {
      setUser(JSON.parse(user));
    }
  }, []);

  const handleEdit = async (id, field, value) => {
    // Ensure the value is a number and clamp it between 0 and 10
    const numValue = Math.min(Math.max(Number(value) || 0, 0), 10);
    const updatedMovies = movies.map((m) => (m.id === id ? { ...m, [field]: String(numValue) } : m));
    setMovies(updatedMovies);

    const { error } = await supabase.from("movies").update({ [field]: String(numValue) }).eq("id", id);
    if (error) {
      console.error("Error updating:", error.message);
    }
  };

  const handleDelete = async (movieId) => {
    try {
      const { error } = await supabase
        .from("movies")
        .delete()
        .eq("id", movieId);

      if (error) throw error;

      // Update local state
      setMovies(movies.filter((movie) => movie.id !== movieId));
    } catch (error) {
      console.error("Error deleting movie:", error.message);
    }
  };

  const addMovie = async () => {
    if (!newMovie.title) return;
    setLoading(true);
    try {
      const movieToInsert = {
        title: newMovie.title,
        description: newMovie.description || '',
        image_url: newMovie.image_url || '',
        release_date: newMovie.release_date || '',
        score_story: newMovie.score_story || '0',
        score_scary: newMovie.score_scary || '0',
        score_acting: newMovie.score_acting || '0',
        score_vibes: newMovie.score_vibes || '0'
      };

      const { error } = await supabase.from("movies").insert([movieToInsert]);
      if (error) throw error;
      
      const { data: updatedData } = await supabase
        .from("movies")
        .select("*")
        .order('id', { ascending: true });
      
      setMovies(updatedData);
      setNewMovie({ 
        title: "", 
        description: "", 
        image_url: "", 
        release_date: "", 
        score_story: "0", 
        score_scary: "0", 
        score_acting: "0", 
        score_vibes: "0" 
      });
      setSearchResults([]);
    } catch (error) {
      console.error("Error adding movie:", error.message);
    } finally {
      setLoading(false);
    }
  };

  const searchMovies = async (query) => {
    if (!query) return setSearchResults([]);
    try {
      const response = await fetch(`https://api.themoviedb.org/3/search/movie?api_key=${API_KEY}&query=${query}`);
      const data = await response.json();
      setSearchResults(data.results || []);
    } catch (error) {
      console.error("Error fetching TMDB movies:", error);
    }
  };

  const calculateTotalScore = (movie) => {
    const scores = [
      Number(movie.score_story) || 0,
      Number(movie.score_scary) || 0,
      Number(movie.score_acting) || 0,
      Number(movie.score_vibes) || 0
    ];
    
    const average = scores.reduce((a, b) => a + b, 0) / scores.length;
    return average.toFixed(1);
  };

  const moveMovie = async (index, direction) => {
    const newMovies = [...movies];
    const newIndex = direction === 'up' ? index - 1 : index + 1;
    
    if (newIndex >= 0 && newIndex < movies.length) {
      // Swap the movies
      [newMovies[index], newMovies[newIndex]] = [newMovies[newIndex], newMovies[index]];
      setMovies(newMovies);
      
      // Update the order in Supabase
      // You'll need to add an 'order' column to your movies table
      const { error } = await supabase
        .from("movies")
        .update({ order: newIndex })
        .eq("id", movies[index].id);
      
      if (error) console.error("Error updating order:", error);
    }
  };

  if (!user) {
    return <Auth />;
  }

  return (
    <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
      <CssBaseline />
      <div style={{ 
        minHeight: "100vh",
        padding: "20px",
        backgroundColor: darkMode ? "#1A1A1A" : "#FFF5F6",
        transition: "background-color 0.3s ease"
      }}>
        <div style={{ 
          display: "flex", 
          flexDirection: "column", 
          alignItems: "center", 
          gap: "20px", 
          width: "100%",
          maxWidth: "800px",
          margin: "0 auto"
        }}>
          <Button
            onClick={() => setDarkMode(!darkMode)}
            sx={{
              position: "absolute",
              right: "20px",
              top: "20px",
              backgroundColor: darkMode ? "#FF85A1" : "#5a1a41",
              color: darkMode ? "#1A1A1A" : "#FFC0CB",
              '&:hover': {
                backgroundColor: darkMode ? "#FFB7C5" : "#7a2a51",
              }
            }}
          >
            {darkMode ? "Light Mode" : "Dark Mode"}
          </Button>
          <Button
            onClick={() => {
              localStorage.removeItem('user');
              window.location.reload();
            }}
            sx={{
              position: 'absolute',
              right: '120px',
              top: '20px',
              backgroundColor: '#5a1a41',
              color: '#FFC0CB',
              '&:hover': { backgroundColor: '#7a2a51' },
            }}
          >
            Logout
          </Button>
          <TableContainer component={Paper} sx={{ backgroundColor: "#000", color: "#FFC0CB", borderRadius: 3, p: 2 }}>
            <Typography variant="h5" align="center" sx={{ color: "#FFC0CB", mb: 2, fontWeight: "bold" }}>
              Movie Ratings
            </Typography>
            <Table sx={{ minWidth: 650 }}>
              <TableHead>
                <TableRow sx={{ backgroundColor: "#5a1a41" }}>
                  {["Move", "Title", "Total Score", "Story", "Scary", "Acting", "Vibes", ""].map((head) => (
                    <TableCell key={head} align="center" sx={{ color: "#FFC0CB", fontWeight: "bold" }}>
                      {head}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {movies.map((movie, index) => (
                  <TableRow key={movie.id}>
                    <TableCell align="center" sx={{ color: "#FFC0CB" }}>
                      <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                        <Button
                          size="small"
                          onClick={() => moveMovie(index, 'up')}
                          disabled={index === 0}
                          sx={{
                            minWidth: '30px',
                            backgroundColor: '#5a1a41',
                            color: '#FFC0CB',
                            '&:hover': { backgroundColor: '#7a2a51' },
                            '&.Mui-disabled': { backgroundColor: '#3a0a21' }
                          }}
                        >
                          ↑
                        </Button>
                        <Button
                          size="small"
                          onClick={() => moveMovie(index, 'down')}
                          disabled={index === movies.length - 1}
                          sx={{
                            minWidth: '30px',
                            backgroundColor: '#5a1a41',
                            color: '#FFC0CB',
                            '&:hover': { backgroundColor: '#7a2a51' },
                            '&.Mui-disabled': { backgroundColor: '#3a0a21' }
                          }}
                        >
                          ↓
                        </Button>
                      </div>
                    </TableCell>
                    {[
                      "title", "total_score", "score_story", "score_scary", "score_acting", "score_vibes",
                    ].map((field) => (
                      <TableCell key={field} align="center" sx={{ color: "#FFC0CB" }}>
                        {field === "title" ? (
                          <Tooltip title={<>
                            <img src={movie.image_url} alt={movie.title} style={{ width: "150px", height: "auto", borderRadius: "5px", display: "block", marginBottom: "5px" }} />
                            <Typography sx={{ color: "#FFF", marginBottom: "5px" }}>Release Date: {movie.release_date}</Typography>
                            <Typography sx={{ color: "#FFF" }}>{movie.description}</Typography>
                          </>} arrow>
                            <Typography sx={{ fontWeight: "bold", color: "#FFC0CB", cursor: "pointer" }}>{movie.title}</Typography>
                          </Tooltip>
                        ) : field === "total_score" ? (
                          <Typography sx={{ color: "#FFC0CB" }}>{calculateTotalScore(movie)}</Typography>
                        ) : (
                          <TextField
                            variant="standard"
                            type="number"
                            inputProps={{ min: 0, max: 10, step: 0.1 }}
                            value={movie[field] || ""}
                            onChange={(e) => handleEdit(movie.id, field, e.target.value)}
                            sx={{ 
                              input: { 
                                color: "#FFC0CB", 
                                textAlign: "center",
                                width: "100%",
                                margin: "0 auto"
                              },
                              "& .MuiInputBase-root": {
                                width: "100%",
                                justifyContent: "center"
                              }
                            }}
                          />
                        )}
                      </TableCell>
                    ))}
                    <TableCell align="center" sx={{ color: "#FFC0CB" }}>
                      <Button
                        variant="contained"
                        color="error"
                        onClick={() => handleDelete(movie.id)}
                        sx={{ minWidth: '30px', backgroundColor: '#5a1a41', '&:hover': { backgroundColor: '#7a2a51' } }}
                      >
                        Delete
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <Typography variant="h6" align="center" sx={{ color: "#FFC0CB", mt: 3 }}>
              Add a New Movie
            </Typography>
            <div style={{ 
              display: "flex", 
              flexDirection: "column", 
              alignItems: "center", 
              gap: "20px", 
              marginTop: "20px",
              width: "100%",
              maxWidth: "800px",
              margin: "20px auto"
            }}>
              <Autocomplete
                freeSolo
                options={searchResults.map((movie) => movie.title)}
                onInputChange={(event, newInputValue) => searchMovies(newInputValue)}
                onChange={(event, newValue) => {
                  const selectedMovie = searchResults.find((movie) => movie.title === newValue);
                  if (selectedMovie) {
                    setNewMovie((prev) => ({
                      ...prev,
                      title: selectedMovie.title,
                      description: selectedMovie.overview,
                      image_url: `https://image.tmdb.org/t/p/w500${selectedMovie.poster_path}`,
                      release_date: selectedMovie.release_date,
                    }));
                  }
                }}
                sx={{
                  width: "100%",
                  "& .MuiOutlinedInput-root": {
                    color: "#FFC0CB",
                    "& fieldset": {
                      borderColor: "#FFC0CB50",
                    },
                    "&:hover fieldset": {
                      borderColor: "#FFC0CB",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#FFC0CB",
                    },
                  },
                  "& .MuiAutocomplete-listbox": {
                    backgroundColor: "#2d2d2d",
                    color: "#FFC0CB",
                  }
                }}
                renderInput={(params) => (
                  <TextField 
                    {...params} 
                    variant="outlined" 
                    placeholder="SEARCH MOVIES" 
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        color: "#FFC0CB",
                      },
                      "& .MuiInputLabel-root": {
                        color: "#FFC0CB",
                      }
                    }}
                  />
                )}
              />
              <Button 
                variant="contained" 
                color="primary" 
                onClick={addMovie}
                disabled={loading}
                sx={{ 
                  minWidth: "200px",
                  backgroundColor: "#5a1a41",
                  '&:hover': { 
                    backgroundColor: "#7a2a51" 
                  }
                }}
              >
                {loading ? "Adding..." : "Add Movie"}
              </Button>
            </div>
          </TableContainer>
        </div>
      </div>
    </ThemeProvider>
  );
}

export default MovieRatings;
